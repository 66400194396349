import React from "react";
import { Link } from "react-router-dom";

const pathname = window.location.pathname;

const NotFound = () => {

  return (
    <div className="blur_bc_all_wrap nbp">
      <div className="error_page">
        <div
          className="hero bg-image"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + "img/slider/404.jpg"})`,
          }}
        >
          <div className="content">
            <h1 data-aos="fade-up" data-aos-duration="1200">
              404!
            </h1>
            <p data-aos="fade-up" data-aos-duration="1200" data-aos-delay="50">
              Oh damn! We can't seem to find the path (<span className="errors">{pathname}</span>) you were looking for. Perhaps the wrong site? Click the button below to return to blurredcolor
            </p>

            <div
              className="blur_bc_button color"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="100"
            >
              <Link to='/'>
                <span className="wrapper">
                  <span className="first">BACK TO HOME</span>
                  <span className="second">BACK TO HOME</span>
                </span>
              </Link>
            </div>
          </div>
        </div>
        {/* End .hero */}
      </div>
    </div>
  );
};

export default NotFound;
