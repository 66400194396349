import React from "react";
import Header from "../components/header/Header";
import Slider from "../components/slider/SliderAnimation";
import Service from "../components/service/ServiceAnimation";
import Projects from "../components/projects/ProjectsAnimation";
import Brand from "../components/Brand";
import Contact from "../components/Contact";
import Footer from "../components/footer/Footer";
import Address from "../components/Address";
import { ReactComponent as Quote } from '../assets/images/quotes.svg';
import background1 from "../assets/images/pattern2.jpg";
import background from "../assets/images/pattern3.jpg";

const HomeLightAnimation = () => {
  document.body.classList.add("main");
  return (
    <div className="blur_bc_all_wrap">
      <div className="home-main">
        <div
          className="blur-fixed-wrapper"
          data-aos="fade-left"
          data-aos-duration="1200"
          data-aos-delay="50"
        >
        </div>
        <Header />
        {/* End Header */}

        <Slider />
        {/* End Slider */}

        {/* SERVICES */}
        <div className="blur_bc_services" id="service" style={{ backgroundImage: `url(${background1})` }}>
          <div className="container">
            <div className="blur_bc_title_holder">
              <span>Services</span>
              <h2>We provide a wide range of digital services</h2>
              <blockquote>
                <em><span className="quotes"><Quote alt="quote" title="jason elliott quote" className='blur_brand_logo' /></span>Exceptional creativity mixed with vision, marketing, and cutting edge technology that'll turns heads."</em>
                <li id="sig" className="aos-init aos-animate" data-aos="fade-right" data-aos-duration="1200"><span id="quote-sig">Jason Elliott</span></li>
              </blockquote>

            </div>
            {/* End blur_bc_title */}
            <Service />
          </div>
        </div>
        {/* /SERVICES */}

        {/* PROJECTS */}
        <div className="blur_bc_projects image" id="projects" style={{ backgroundImage: `url(${background})` }}>
          <div className="container">
            <div className="blur_bc_title_holder">
              <span>Projects</span>
              <h2>Recent Projects</h2>
              <p>
                From graphic design/ photography to full blown eCommerce and Marketing solutions. Below is a look at some recent
                projects from a diverse set of clients.
              </p>
            </div>
            {/* End blur_bc_title */}
            <Projects />
          </div>
          <div className="container">
          <div className="blur_bc_title_holder">
              <h2>Past & Present</h2>
              <p>
                From nascent startups to large enterprises and everything in between, here’s a small glimpse at our client roster:
              </p>
            </div>
            <Brand />
          </div>
        </div>
        {/* /PROJECTS */}
        <div className="blur_bc_testimonials">
          <div className="container">
            <div className="blur_bc_title_holder">
              <h2>Have questions?</h2>
              <p>
                Feel free to contact us to discuss our past experience in more detail and how it may align with your next project. 
              </p>
            </div>
          </div>
        </div>
        {/*  CONTACT */}
        <div className="blur_bc_contact" id="contact">
          <div className="container">
            <div className="contact_inner">
              <div
                className="left"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <div className="blur_bc_title_holder">
                  <span>Contact</span>
                  <h2>Let's discuss your project</h2>
                </div>
                <div className="short_list">
                  <Address />
                </div>
              </div>
              {/* End .left */}

              <div
                className="right"
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay="150"
              >
                <div className="title">
                  <p>
                    Fill out the contact form below
                    <br />
                    <span>and we'll get back to you ASAP.</span>
                  </p>
                </div>
                <div className="fields">
                  <Contact />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /CONTACT */}

        {/* COPYRIGHT */}
        <div className="blur_bc_copyright">
          <div className="container">
            <Footer />
          </div>
        </div>
        {/* /COPYRIGHT */}
      </div>
    </div>
  );
};

export default HomeLightAnimation;
