import React from "react";
// import ModalVideo from "react-modal-video";
import SimpleReactLightbox from "simple-react-lightbox";
import background from "../../assets/images/pattern3.jpg";
//import { SRLWrapper } from "simple-react-lightbox";

const Projects = () => {
  // const [isOpen, setOpen] = useState(false);
  // const [isOpen2, setOpen2] = useState(false);
  return (
    <SimpleReactLightbox>
      <div className="projects_list">
      <div
        className="image"
        style={{ backgroundImage: `url(${background})` }}
      ></div>
        <ul className="gallery_zoom">
          <li data-aos="fade-right" data-aos-duration="1200">
            <div className="list_inner">
              <a
                href="https://originscollectionwines.com"
                target="_blank"
                rel="noopener noreferrer"
                className="title"
              >
                <h3>Fetzer/ Concha y Toro</h3>
                <span>originscollectionwines.com</span>
              </a>
              <div className="blur_overlay">
                <img 
                  src="img/svg/origins.svg" 
                  alt="Origins Collection Wines Logo"
                />
              </div>
                <img
                  src="img/projects/origins.jpg"
                  alt="Origins Collection Wines"
                />
            </div>
          </li>

          <li
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="100"
          >
            <div className="list_inner">
              <a
                href="https://aleafinawine.com"
                target="_blank"
                rel="noopener noreferrer"
                className="title"
              >
                <h3>Fetzer/ Concha y Toro</h3>
                <span>aleafinawine.com</span>
              </a>
              <div className="blur_overlay">
                <img 
                  src="img/svg/alea.svg" 
                  alt="Alea Fina Logo"
                />
              </div>
              <img
                src="img/projects/alea.jpg"
                alt="Alea Fina Wines"
              />
            </div>
          </li>
          <li
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="200"
          >
            <div className="list_inner">
              <a
                className="title"
                target="_blank"
                rel="noopener noreferrer"
                href="https://donmelchorus.com"
              >
              <h3>Fetzer/ Concha y Toro</h3>
              <span>donmelchorus.com</span>
              </a>
              <div className="blur_overlay">
                <img 
                  src="img/svg/melchor.svg" 
                  alt="Don Melchor Logo"
                />
              </div>
              <img 
                src="img/projects/melchor.jpg" 
                alt="Don Melchor" 
                />
            </div>
          </li>
          {/* End image popup */}

          <li
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="500"
          >
            <div className="list_inner">
              <a
                className="title"
                target="_blank"
                rel="noopener noreferrer"
                href="https://judyklimek.com"
              >
                <h3>Judy Klimek</h3>
                <span>judyklimek.com</span>
              </a>
              <div className="blur_overlay">
                <img 
                  src="img/svg/jk-logo.svg" 
                  alt="Judy Klimek Statement Jewelry"
                />
              </div>
              <img 
                src="img/projects/judy.jpg" 
                alt="Judy Klimek Jewelry" 
                />
            </div>
          </li>
          {/* End image popup */}

          <li
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="400"
          >
            <div className="list_inner">
              <a
                className="title"
                target="_blank"
                rel="noopener noreferrer"
                href="https://rjbservice.com"
              >
                <h3>RJB Service Station</h3>
                <span>rjbservice.com</span>
              </a>
              <div className="blur_overlay">
                <img 
                  src="img/svg/rjb.svg" 
                  alt="RJB Service Station"
                />
              </div>
              <img 
                src="img/projects/rjb.jpg" 
                alt="RJB Service Station" 
                />
            </div>
          </li>
          {/* End image popup */}

          <li
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="300"
          >
            <div className="list_inner">
              <a
                className="title"
                target="_blank"
                rel="noopener noreferrer"
                href="https://rogueco.com"
              >
                <h3>Rogue Games</h3>
                <span>rogueco.com</span>
              </a>
              <div className="blur_overlay">
                <img 
                  src="img/svg/rogue.svg" 
                  alt="Rogue Games"
                />
              </div>
              <img 
                src="img/projects/rogue.jpg" 
                alt="Rogue Games" 
                />
            </div>
          </li>
          {/* End image popup */}
        </ul>
      </div>
    </SimpleReactLightbox>
    

  );
};

export default Projects;
