import React from "react";
import { ReactComponent as Inductors } from '../assets/images/inductors.svg';
import { ReactComponent as CT } from '../assets/images/ctparts.svg';
import { ReactComponent as Levy } from '../assets/images/levy.svg';
import { ReactComponent as CareerEge } from '../assets/images/careeredge.svg';
import { ReactComponent as Summit } from '../assets/images/summit.svg';
import { ReactComponent as IBI } from '../assets/images/ibigroup.svg';
import { ReactComponent as TSA } from '../assets/images/tsa.svg';
import { ReactComponent as James } from '../assets/images/james.svg';
import { ReactComponent as Melchor } from '../assets/images/melchor.svg';
import { ReactComponent as Alea } from '../assets/images/alea.svg';
import { ReactComponent as Origins } from '../assets/images/origins.svg';
import { ReactComponent as Rogue } from '../assets/images/rogue.svg';
import { ReactComponent as Fetzer } from '../assets/images/fetzer.svg';
import { ReactComponent as Concha } from '../assets/images/concha.svg';
import { ReactComponent as Judy } from '../assets/images/jk-logo.svg';
import { ReactComponent as RJB } from '../assets/images/rjb.svg';


export default function SimpleSlider() {
  return (
    <ul className="blur_brands">
        <li className="item">
          <James alt="james michelle logo" title="James Michelle Jewelry" className='blur_brand_logo' />
        </li>

        <li className="item">
          <Inductors alt="inductors logo" title="Inductors Inc" className='blur_brand_logo' />
        </li>

        <li className="item">
        <CT alt="ctparts logo" title="Central Technologies" className='blur_brand_logo' />
        </li>

        <li className="item">
          <Levy alt="levy craig logo" title="Levy Craig Law Firm" className='blur_brand_logo' />
        </li>

        <li className="item">
          <CareerEge alt="careeredge logo" title="CareeerEdge Funders" className='blur_brand_logo' />
        </li>

        <li className="item">
          <Summit alt="summit hill logo" title="Summit Hill Insurance" className='blur_brand_logo' />
        </li>

        <li className="item">
          <IBI alt="ibi group logo" title="IBI Group" className='blur_brand_logo' />
        </li>

        <li className="item">
          <TSA alt="total spectrum advertising logo" title="Total Spectrum Advertising" className='blur_brand_logo' />
        </li>

        <li className="item">
          <Melchor alt="don melchor logo" title="Don Melchor Wines" className='blur_brand_logo' />
        </li>

        <li className="item">
          <Alea alt="alea fina logo" title="Alea Fina Wines" className='blur_brand_logo' />
        </li>

        <li className="item">
          <Origins alt="origins collection wines logo" title="Origin Collection Wines" className='blur_brand_logo' />
        </li>

        <li className="item">
          <Rogue alt="rogue games logo" title="Rogue Games" className='blur_brand_logo' />
        </li>

        <li className="item">
          <Fetzer alt="fetzer wines logo" title="Fetzer Wines" className='blur_brand_logo' />
        </li>

        <li className="item">
          <Concha alt="concha y toro logo" title="Concha y Toro" className='blur_brand_logo' />
        </li>

        <li className="item">
          <Judy alt="Judy Klimeek logo" title="Judy Klimek Statement Jewelry" className='blur_brand_logo' />
        </li>

        <li className="item">
          <RJB alt="don melchor logo" title="RJB Service Station" className='blur_brand_logo' />
        </li>
    </ul>
  );
}
