import React from "react";
import Modal from "react-modal";
import background from "../../assets/images/pattern2.jpg";

Modal.setAppElement("#root");

const ServiceAnimation = () => {
  return (
    <div className="service_list">
      <div
        className="image"
        style={{ backgroundImage: `url(${background})` }}
      ></div>
      <ul>
        <li>
          <div
            className="list_inner"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="100"
          >
            <img className="svg" src="img/svg/2.svg" alt="" />
            <div className="service_title">
              <h3>Branding &amp; Design</h3>
            </div>
          </div>
        </li>
         <li>
          <div
            className="list_inner"
            data-aos="fade-right"
            data-aos-duration="1200"
          >
            <img className="svg" src="img/svg/1.svg" alt="" />
            <div className="service_title">
              <h3>Photography & Digital Media</h3>
            </div>
          </div>
        </li>

        <li>
          <div
            className="list_inner"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="200"
          >
            <img className="svg" src="img/svg/3.svg" alt="" />
            <div className="service_title">
              <h3>Web Dev & eCommerce</h3>
            </div>
          </div>
        </li>

        <li>
          <div
            className="list_inner"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="300"
          >
            <img className="svg" src="img/svg/4.svg" alt="" />
            <div className="service_title">
              <h3>Marketing & Consulting</h3>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default ServiceAnimation;
