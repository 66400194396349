import React from "react";

const Address = () => {
  return (
    <ul>
      <li>
        <img className="svg" src="img/svg/phone.svg" alt="" />
        Jason Elliott <br></br>
        <a href="Tel: 9492578945">(949) 257 - 8945</a><br></br>
        <a href="mailto:jelliott@blurredcolor.com">jelliott@blurredcolor.com</a>
      </li>
      <li>
        <img className="svg" src="img/svg/mail.svg" alt="" />
        <span>
          <a href="mailto:info@blurredcolor.com">info@blurredcolor.com</a>
        </span>
      </li>
    </ul>
  );
};

export default Address;
