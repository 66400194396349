import React from "react";

const Footer = () => {
  return (
    <>
      <div className="inner">
        <div className="copy">
          <p>
            &copy; {new Date().getFullYear()} {" "}
            <a
              href="https://blurredcolor.com"
              rel='noreferrer'
            >
              blurredcolor llc
            </a>
            . All rights reserved. <a href="https://blurredcolor.com/privacy" >Privacy</a>
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
