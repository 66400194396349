import React from "react";
import Social from "../Social";
import SocialTwo from "../SocialTwo";
import TextLoop from "react-text-loop";
import background from "../../assets/images/pattern.jpg";

const SliderAnimation = () => {
  return (
    //    HERO
    <div className="blur_bc_hero" id="home">
      <div className="background">
        <div
          className="image"
          style={{ backgroundImage: `url(${background})` }}
        >

      </div>
      <div className="overlay"></div>
      </div>
      {/* End bg */}

      <div className="go-to go-to-next">
        <a href="#service">
          <span></span>
        </a>
      </div>
      {/* End animated goto button */}

      <div className="container">
        <div className="content">
          <div className="content_inner">
            {/* <h3 className="name" data-aos="fade-up" data-aos-duration="1200">
              Hi! I'm Jason Elliott
            </h3> */}
            <h1
              className="job"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="50"
            >
              <TextLoop>
                <p className="loop-text lead">Branding</p>
                <p className="loop-text lead">Digital Media</p>
                <p className="loop-text lead">Web Development</p>
                <p className="loop-text lead">eCommerce</p>
                <p className="loop-text lead">Marketing</p>
                <p className="loop-text lead">Consulting</p>
              </TextLoop>
              {/* <br /> <span>Based in California.</span> */}
            </h1>
            <p
              className="text"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="100"
            >
              We are a small Digital Agency creating responsive web applications (from simple to complex solutions) that are not only visually stunning, 
              but functional across all platforms. Now, who doesn't want that? 
            </p>
            <div
              className="mobile_social"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="150"
            >
              <Social />
            </div>
            {/* End .social */}

            <div
              className="blur_bc_button"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <a className="anchor" href="#contact">
                <span className="wrapper">
                  <span className="first">Get in Touch</span>
                  <span className="second">Get in Touch</span>
                </span>
              </a>
            </div>
            {/* End blur_bc_button */}
          </div>
        </div>
      </div>
      {/* End .container */}

      <div className="social" data-aos="fade-left" data-aos-duration="1200">
        <SocialTwo />
      </div>
      {/* End .social */}
    </div>
    //HERO
  );
};

export default SliderAnimation;
