import React from "react";
import Home from "../views/Home";
import Privacy from "../views/Privacy";
import NotFound from "../views/NotFound";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollTopBehaviour from "../components/ScrollTopBehaviour";

const Routez = () => {
  return (
    <>
      <Router forceRefresh={true}>
        <ScrollTopBehaviour />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </>
  );
};

export default Routez;
